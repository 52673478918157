<template>
  <v-dialog v-model="showSubscriptionDialog" persistent rounded="md" max-width="600" class="subscription-dialog">
    <v-card prepend-icon="mdi-alert-decagram">
      <template #title>
        <strong class="py-0 my-0">Are you sure?</strong>
      </template>
      <v-divider />
      <v-card-text class="text-left my-0 py-0">
        <v-list :lines="false" density="compact" class="plan-features">
          <v-list-item class="my-2">
            <v-list-item-title>{{ listTitle }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(item, index) in listItems" :key="index">
            <v-list-item-title>
              <v-icon :color="iconColor" class="px-2 mb-1">{{ iconName }}</v-icon>
              {{ item }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-alert v-if="alert.show" :type="alert.type" variant="tonal" rounded="md" density="compact"
          icon="mdi-information" class="mb-1 mt-5 text-black">
          <p class="text-black mb-0">{{ alert.message }}</p>
        </v-alert>
      </v-card-text>
      <v-card-actions class="mb-3 mr-4">
        <template v-if="isPositiveAction">
          <v-btn rounded="md" color="grey-darken-2" variant="text" class="text-none text-capitalize font-weight-bold"
            :disabled="loader.close" @click="closeDialog(false)">
            Close
          </v-btn>
          <v-btn rounded="md" color="primary-red" variant="elevated" class="text-none text-capitalize font-weight-bold"
            :loading="loader.action" @click="handleConfirm">
            {{ actionButtonText }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn rounded="md" color="primary-red" variant="elevated" class="text-none text-capitalize font-weight-bold"
            :disabled="loader.close" @click="closeDialog(false)">
            keep my leads &amp; data
          </v-btn>
          <v-btn rounded="md" color="grey-darken-2" variant="text" :loading="loader.action"
            class="text-none text-capitalize font-weight-bold" @click="handleConfirm">
            {{ actionButtonText }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import * as actionTypes from '@/store/action-types.js'
import environments from '@/shared/constants/environments'
import opType from '@/shared/constants/plan/paddle/action'
import userMixin from '@/mixins/user'
import helperMixin from '@/mixins/helper'
import planMixin from '@/mixins/plan'
import config from '@/api/config'

const ACTION_BUTTON_LABELS = {
  [opType.subscribeWithinTrial]: 'Subscribe Plan',
  [opType.subscribe]: 'Subscribe Plan',
  [opType.billing]: 'Reactivate Account',
  [opType.resume]: 'Reactivate Plan',
  [opType.pause]: 'Cancel Subscription',
  [opType.cancel]: 'Cancel Trial',
  [opType.downgrade]: 'Downgrade Subscription'
}

const ACTION_TEXT_MAPPING = {
  [opType.subscribeWithinTrial]: 'subscribe',
  [opType.subscribe]: 'subscribe',
  [opType.billing]: 'reactivate',
  [opType.resume]: 'reactivate',
  [opType.pause]: 'cancel',
  [opType.cancel]: 'cancel',
  [opType.downgrade]: 'downgrade'
}

export default {
  name: 'SubscriptionDialog',
  mixins: [userMixin, helperMixin, planMixin],
  props: {
    modelValue: Boolean,
    dialogData: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      opType,
      loader: {
        close: false,
        action: false
      },
      alert: {
        type: null,
        message: null,
        show: false
      }
    }
  },
  mounted() {
    this.initializePaddle()
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
    showSubscriptionDialog: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    action() {
      return this.dialogData.action
    },
    isPositiveAction() {
      return [opType.subscribe, opType.upgrade, opType.resume, opType.subscribeWithinTrial].includes(this.action)
    },
    listTitle() {
      const actionText = ACTION_TEXT_MAPPING[this.action] || this.action
      return `When you ${actionText} you will ${this.isPositiveAction ? 'gain' : 'lose'} the following`
    },
    listItems() {
      const { planData } = this.dialogData
      return this.isPositiveAction
        ? [
          `Access up to ${planData.forms} forms`,
          `Capture up to ${planData.leads} Leads/month`,
          ...(planData.partial_leads > 0 ? [`Efficiently capture up to ${planData.partial_leads} Partial Leads/month`] : []),
          'All features',
          'No Risk - Cancel anytime'
        ]
        : [
          `Access to more than ${planData.forms} forms.`,
          'Your embedded forms will stop loading on your pages.',
          'Access to your leads and response data',
          'Full flexibility of your form design'
        ]
    },
    iconName() {
      return this.isPositiveAction ? 'mdi-check-decagram' : 'mdi-close-octagon'
    },
    iconColor() {
      return this.isPositiveAction ? 'primary-green' : 'primary-red'
    },
    actionButtonText() {
      const action = this.dialogData.action
      return ACTION_BUTTON_LABELS[action] || `${action} Plan`
    }
  },
  methods: {
    setLoader(isLoading) {
      this.loader = {
        close: isLoading,
        action: isLoading
      }
    },
    resetAlert() {
      this.alert = {
        type: null,
        message: null,
        show: false
      }
    },
    showAlert(isError, message) {
      this.alert = {
        type: isError ? 'error' : 'success',
        message,
        show: true
      }
    },
    closeDialog(flag) {
      if (flag) {
        setTimeout(() => {
          this.showSubscriptionDialog = false
          this.setLoader(false)
          this.resetAlert()
        }, 3000)
      } else {
        this.showSubscriptionDialog = false
        this.setLoader(false)
        this.resetAlert()
      }
    },
    initializePaddle() {
      if (window.Paddle) {
        window.Paddle.Setup({ vendor: config.paddle.vendorId })

        if ([environments.LOCAL, environments.DEV].includes(config.environment)) {
          window.Paddle.Environment.set('sandbox')
        }

        if (this.userMixin_isPastDueUser) {
          this.planMixin_loadUpdateUrl()
        }
        if (this.userMixin_showTwentyFourHourDiscount) {
          this.planMixin_loadDiscountCoupon()
        }
      } else {
        console.error('Paddle is not initialized')
      }
    },
    handleConfirm() {
      const { action, newPlanId } = this.dialogData
      const handlers = {
        [opType.subscribe]: () => this.subscribeToPlan(newPlanId),
        [opType.subscribeWithinTrial]: () => this.subscribeWithinTrial(newPlanId),
        [opType.upgrade]: () => this.updateSubscription(newPlanId, opType.upgrade),
        [opType.pause]: () => this.pauseSubscription(),
        [opType.resume]: () => this.resumeSubscription(),
        [opType.cancel]: () => this.cancelSubscription(),
        [opType.downgrade]: () => this.updateSubscription(newPlanId, opType.downgrade),
        [opType.billing]: () => this.updateBillingDetails()
      }

      const handler = handlers[action]
      if (handler) {
        handler()
      }
    },
    subscribeToPlan: function (newPlanId) {
      this.setLoader(true)
      this.planMixin_resetSubscriptionStatus()

      const metaData = {
        user_id: this.user.id,
      }

      let checkoutConfig = {
        product: newPlanId,
        theme: 'none',
        email: this.user.email,
        passthrough: JSON.stringify(metaData),
        marketingConsent: this.user.subscribe_newsletter,
        successCallback: () => {
          this.pollingSubscriptionStatus(() => {
            this.subscriptionStatus.success = true
            this.showAlert(false, 'You have successfully subscribed to this plan. Please wait...')
            this.closeDialog(true)
          })
        },
        closeCallback: (data) => {
          this.setLoader(false)
          this.planMixin_resetSubscriptionStatus()
        },
        errorCallback: (error) => {  // Add error callback
          this.setLoader(false)
          this.planMixin_resetSubscriptionStatus()
          this.showAlert(true, 'Subscription process failed. Please try again.')
        }
      }

      if (this.discount.add) {
        checkoutConfig.coupon = this.discount.coupon
      }

      // Ensure that Paddle is loaded and the DOM is ready
      if (window.Paddle) {
        this.$nextTick(() => {
          window.Paddle.Checkout.open(checkoutConfig)
        })
      } else {
        console.error('Paddle is not loaded')
      }
    },
    updateSubscription: function (newPlanId, acType) {
      if (!this.user.plan.subscription) {
        this.showAlert(true, 'Unable to fetch current subscription for this action.')
        return
      }

      this.setLoader(true)
      this.planMixin_resetSubscriptionStatus()
      this.subscriptionStatus.action = acType

      this.$store.dispatch('subscription/updateSubscription', {
        id: this.user.plan.subscription.id,
        params: { planId: newPlanId }
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          this.subscriptionStatus.success = true
          this.showAlert(false, `You have successfully ${acType} to this plan. Please wait...`)
          this.closeDialog(true)
        })
      }, (err) => {
        this.subscriptionStatus.error = true
        this.showAlert(true, `Unable to ${acType} to this plan. Please try again later.`)
        this.setLoader(false)
      })
    },
    pauseSubscription: function () {
      if (!this.user.plan.subscription) {
        this.showAlert(true, 'Unable to fetch current subscription for this action.')
        return
      }

      this.setLoader(true)
      this.planMixin_resetSubscriptionStatus()
      this.subscriptionStatus.action = this.dialogData.action

      this.$store.dispatch('subscription/pauseSubscription', {
        id: this.user.plan.subscription.id
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          this.subscriptionStatus.success = true
          this.showAlert(false, 'You have successfully cancelled your subscription. Please wait...')
          this.closeDialog(true)
        })
      }, (err) => {
        this.subscriptionStatus.error = true
        this.showAlert(true, 'Unable to pause this subscription. Please try again later.')
        this.setLoader(false)
      })
    },
    resumeSubscription: function () {
      if (!this.user.plan.subscription) {
        this.showAlert(true, 'Unable to fetch current subscription for this action.')
        return
      }

      this.setLoader(true)
      this.planMixin_resetSubscriptionStatus()
      this.subscriptionStatus.action = this.dialogData.action

      this.$store.dispatch('subscription/resumeSubscription', {
        id: this.user.plan.subscription.id
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          this.subscriptionStatus.success = true
          this.showAlert(false, 'You have successfully resumed your subscription. Please wait...')
          this.closeDialog(true)
        })
      }, (err) => {
        this.subscriptionStatus.error = true
        this.showAlert(true, 'Unable to reactivate this subscription. Please try again later.')
        this.setLoader(false)
      })
    },
    cancelSubscription: function () {
      if (!this.user.plan.subscription) {
        this.showAlert(true, 'Unable to fetch current subscription for this action.')
        return
      }

      this.setLoader(true)
      this.planMixin_resetSubscriptionStatus()
      this.subscriptionStatus.action = this.dialogData.action

      this.$store.dispatch('subscription/cancelSubscription', {
        id: this.user.plan.subscription.id
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          this.subscriptionStatus.success = true
          this.showAlert(false, 'You have successfully cancelled your subscription. Please wait...')
          this.closeDialog(true)
        })
      }, (err) => {
        this.subscriptionStatus.error = true
        this.showAlert(true, 'Unable to cancel this subscription. Please try again later.')
        this.setLoader(false)
      })
    },
    subscribeWithinTrial: function (newPlanId) {
      if (!this.user.plan.subscription) {
        this.showAlert(true, 'Unable to fetch current subscription for this action.')
        return
      }

      this.setLoader(true)
      this.planMixin_resetSubscriptionStatus()
      this.subscriptionStatus.action = this.dialogData.action

      this.$store.dispatch('subscription/listPayment', {
        id: this.user.plan.subscription.id
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          this.planMixin_resetSubscriptionStatus()
          this.updateSubscription(newPlanId)
        })
      }, (err) => {
        this.subscriptionStatus.error = true
        this.showAlert(true, 'Unable to subscribe this plan. Please try again later.')
        this.setLoader(false)
      })
    },
    updateBillingDetails: function () {
      this.setLoader(true)
      this.planMixin_resetSubscriptionStatus()
      this.subscriptionStatus.action = this.dialogData.action

      let checkoutConfig = {
        override: this.pastDue.bankUrl,
        successCallback: () => {
          this.pollingSubscriptionStatus(() => {
            this.subscriptionStatus.success = true
            this.showAlert(false, 'You have successfully updated your billing details. Please wait...')
            this.closeDialog(true)
          })
        },
        closeCallback: (data) => {
          this.setLoader(false)
          this.planMixin_resetSubscriptionStatus()
        },
        errorCallback: (error) => {  // Add error callback
          this.setLoader(false)
          this.planMixin_resetSubscriptionStatus()
          this.showAlert(true, 'Subscription process failed. Please try again.')
        }
      }

      if ([environments.LOCAL, environments.DEV].includes(config.environment)) {
        window.Paddle.Environment.set('sandbox')
      }

      // Ensure that Paddle is loaded and the DOM is ready
      if (window.Paddle) {
        this.$nextTick(() => {
          window.Paddle.Checkout.open(checkoutConfig)
        })
      } else {
        console.error('Paddle is not loaded')
      }
    },
    pollingSubscriptionStatus(callback = null) {
      this.subscriptionStatus.intervalCounter = 0
      this.subscriptionStatus.success = false
      this.subscriptionStatus.error = false

      const pollingInterval = 4000
      const intervalLimit = 10 // Adjust as needed

      // Start polling at regular intervals
      this.subscriptionStatus.interval = setInterval(async () => {
        this.subscriptionStatus.intervalCounter++

        try {
          // Sync user data to check the subscription status
          const userSynced = await this.syncLocalUser()

          // If user data is successfully synced and subscription is active
          if (userSynced) {
            clearInterval(this.subscriptionStatus.interval)
            this.subscriptionStatus.success = true
            this.handleSubscription(false, this.subscriptionStatus.action)
            if (callback) callback(true)
          }
        } catch (err) {
          console.error('Error:', err)
        }

        // Check if polling limit is reached
        if (this.subscriptionStatus.intervalCounter >= intervalLimit) {
          clearInterval(this.subscriptionStatus.interval)
          this.subscriptionStatus.error = true

          try {
            // Attempt a final sync
            await this.syncLocalUser()
          } finally {
            this.handleSubscription(true, this.subscriptionStatus.action)
            if (callback) callback(false)
          }
        }
      }, pollingInterval)
    },
    async syncLocalUser() {
      try {
        // Store token for authentication
        if (this.mixin_user.token) {
          localStorage.setItem('token', JSON.stringify(this.mixin_user.token))
        }

        const response = await this.$store.dispatch(actionTypes.FETCH_USER, {
          userId: this.mixin_user.id || this.user.id
        })

        const user = response?.data?.data

        // Remove token after fetching user data
        if (this.mixin_user.token) {
          localStorage.removeItem('token')
        }

        // Check if user has an active plan
        if (user?.plan?.subscription) {
          await this.$store.dispatch(actionTypes.UPDATE_USER_LOCAL, user)
          localStorage.setItem('user', JSON.stringify(user))
          return true // User has an active subscription
        }

        throw new Error('No active subscription found')
      } catch (error) {
        return false
      }
    },
    handleSubscription(isError, status) {
      if (!isError) {
        this.subscriptionStatus.success = true
      } else {
        this.subscriptionStatus.error = true
      }
    }
  }
}
</script>
